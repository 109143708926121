import Stall from "./Stall";
import LionsClub from "./LionsClub";
import InfoModal from "./InfoModal";

const RegularRow = ({ number }) => {
    let first_label = number > 15 ? '' : 'A' + (16 - number);
    let eighth_label = 'C' + number;
    let second_label = '';
    let third_label = '';
    let fourth_label = '';
    let fifth_label = '';
    let sixth_label = '';
    let seventh_label = '';
    if (number < 11) {
        second_label = 'D' + number;
        third_label = 'D' + (10 + number);
        fourth_label = 'E' + number;
        fifth_label = 'E' + (10 + number);
        sixth_label = 'F' + number;
        seventh_label = 'F' + (10 + number);
    } else if (number > 11) {
        second_label = 'G' + (number - 11);
        third_label = 'G' + (number - 1);
        fourth_label = 'H' + (number - 11);
        fifth_label = 'H' + (number - 1);
        sixth_label = 'I' + (number - 11);
        seventh_label = 'I' + (number - 1);
    }
    return (
        <>
            {first_label ? <InfoModal triggerElement={<Stall spot={{ label: first_label }} />} /> : <div className="column"></div>}
            <div className="column"></div>
            <InfoModal triggerElement={<Stall spot={{ label: second_label }} />} />
            <InfoModal triggerElement={<Stall spot={{ label: third_label }} />} />
            <div className="column"></div>
            {number === 1 ?
                <>
                    <InfoModal triggerElement={<LionsClub spot={{ label: '' }} />} />
                    <InfoModal triggerElement={<LionsClub spot={{ label: '' }} />} />
                </> :
                <>
                    <InfoModal triggerElement={<Stall spot={{ label: fourth_label }} />} />
                    <InfoModal triggerElement={<Stall spot={{ label: fifth_label }} />} />
                </>
            }
            <div className="column"></div>
            <InfoModal triggerElement={<Stall spot={{ label: sixth_label }} />} />
            <InfoModal triggerElement={<Stall spot={{ label: seventh_label }} />} />
            <div className="column"></div>
            <InfoModal triggerElement={<Stall spot={{ label: eighth_label }} />} />
        </>
    )
};

export default RegularRow;