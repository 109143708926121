import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import ReservationForm from './ReservationForm';

const ApplicationModal = ({ spot }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button className="ui blue button">Reserve Spot</Button>}
        >
            <Modal.Header><div className="modal-header"><h2>Reservation Form for Spot {spot?.label}</h2></div></Modal.Header>
            <Modal.Content>
                <ReservationForm spot={spot} />
            </Modal.Content>
        </Modal>
    );
};

export default ApplicationModal;