import { useContext } from 'react'
import { ReservationContext } from '../contexts/ReservationContext';

const Stall = ({ spot, wide, ...rest }) => {
    const reservations = useContext(ReservationContext);
    const spotReserved = reservations.find(e => e.spot === spot?.label && e.reserved === 1);
    if (spot.label) {
        let columnClass = 'column spot';
        let stallClass = 'stall selectable';
        if (wide) {
            columnClass = 'double-column spot';
            stallClass = 'wide-stall selectable';
        }
        if (spotReserved) {
            stallClass += ' reserved'
        }
        return (
            <div className={columnClass} {...rest}>
                <div className={stallClass} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span>{spot?.label}</span>
                </div>
            </div>
        );
    }
    return (
        <div className="column"></div>
    );
};

export default Stall;