import FoodTruckRow from "./FoodTruckRow";
import EmptyRow from "./EmptyRow";
import RegularRow from "./RegularRow";
import EntranceRow from "./EntranceRow";

const Map = () => {
    let regularRows = []
    for (let i = 1; i <= 21; i++) {
        regularRows.push(<div className="row" key={i} ><RegularRow key={'row' + i} number={i} /></div>);
    }
    return (
        <div className="map" style={{ padding: '5vw' }}>
            <FoodTruckRow startingSpot={1} />
            <EmptyRow />
            {regularRows}
            <EmptyRow />
            <FoodTruckRow startingSpot={6} />
            <EmptyRow />
            <EntranceRow />
        </div>
    );
};

export default Map;