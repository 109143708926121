import { useContext } from 'react'
import { ReservationContext } from '../contexts/ReservationContext';

const FoodTruck = ({ spot, ...rest }) => {
    const reservations = useContext(ReservationContext);
    const spotReserved = reservations.find(e => e.spot === spot?.label && e.reserved === 1);
    let foodTruckClass = 'food-truck selectable';
    if (spotReserved) {
        foodTruckClass += ' reserved';
    }
    return (
        <div className="double-column spot" {...rest}>
            <div className={foodTruckClass} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>{spot?.label}</span>
            </div>
        </div>
    );
};

export default FoodTruck;