import Bathroom from "./Bathroom";
import FoodTruck from "./FoodTruck";
import InfoModal from "./InfoModal";

const FoodTruckRow = ({ startingSpot }) => {
    return (
        <div className="row">
            <Bathroom />
            <div className="column"></div>
            <InfoModal triggerElement={<FoodTruck spot={{ label: 'B' + (startingSpot) }} />} />
            <InfoModal triggerElement={<FoodTruck spot={{ label: 'B' + (startingSpot + 1) }} />} />
            <InfoModal triggerElement={<FoodTruck spot={{ label: 'B' + (startingSpot + 2) }} />} />
            <InfoModal triggerElement={<FoodTruck spot={{ label: 'B' + (startingSpot + 3) }} />} />
            <InfoModal triggerElement={<FoodTruck spot={{ label: 'B' + (startingSpot + 4) }} />} />
        </div>
    );
};

export default FoodTruckRow;