import React from 'react';
import { Modal, Button, ModalContent } from 'semantic-ui-react'
import LionsClub from './LionsClub';
import FoodTruck from './FoodTruck';
import Stall from './Stall';
import { NonFoodVendorInfo, FoodVendorInfo } from '../StallInfo'
import ApplicationModal from './ApplicationModal';
import { ReservationContext } from '../contexts/ReservationContext';


const InfoModal = ({ triggerElement }) => {
    const reservations = React.useContext(ReservationContext);
    const spotReserved = reservations.find(e => e.spot === triggerElement?.props?.spot?.label && e.reserved === 1);
    const [open, setOpen] = React.useState(false);
    const profile = JSON.parse(localStorage.getItem('profile'));
    let header = 'Information for spot - ' + triggerElement?.props?.spot?.label;
    let stallInfo;
    switch (triggerElement.type) {
        case LionsClub:
            header = 'Lions Club Booth';
            break;
        case FoodTruck:
            header = 'Food Vendor Space - ' + triggerElement?.props?.spot?.label;
            stallInfo = FoodVendorInfo;
            break;
        case Stall:
            header = 'Non Food Vendor Space - ' + triggerElement?.props?.spot?.label;
            stallInfo = NonFoodVendorInfo;
            break;
        default:
    }

    const clearReservation = (event) => {
        event.preventDefault();
        const resp = fetch('api/clearSpot', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            body: JSON.stringify({ 'spot': triggerElement?.props?.spot?.label })
        });
        resp
            .then(response => response.json())
            .then(data => {
                console.log(data);
                window.location.reload();
            }).catch(() => alert('Error submitting form'));
    };
    if (spotReserved) {
        return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={triggerElement}
            >
                <Modal.Header><div className="modal-header"><h2>{spotReserved.spot} - {spotReserved.name}</h2></div></Modal.Header>
                <ModalContent>
                    <div className="modal-header">
                        <h3>**RESERVED**</h3>
                        <h3>Description of items being sold: {spotReserved.itemsToSell}</h3>
                    </div>
                    {profile?.isAdmin ?
                        <Button className="ui blue button" onClick={clearReservation}>Clear Reservation</Button>
                        :
                        <></>
                    }
                </ModalContent>
            </Modal>
        )
    };
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={triggerElement}
        >
            <Modal.Header><div className="modal-header"><h2>{header}</h2></div></Modal.Header>
            {stallInfo ?
                <Modal.Content>
                    <div className="form">
                        <div className="form-row">
                            <h3>The size of this space is {stallInfo.size}.</h3>
                        </div>
                        <div className="form-row">
                            <h3>The price of this space before February 1st, 2024 is {stallInfo.earlyFee}.</h3>
                        </div>
                        <div className="form-row">
                            <h3>The price of this space after January 31st, 2024 is {stallInfo.lateFee}.</h3>
                        </div>
                        <div className="form-row">
                            <a href={stallInfo.vendorApplicationFile} rel="noreferrer" download="Peaches To Beaches Vendor Application" target='_blank'>
                                <Button><i className="cloud download icon"></i> Download PDF Application</Button>
                            </a>
                        </div>
                        {profile?.isAdmin ?
                            <div className="form-row">
                                <ApplicationModal spot={triggerElement?.props?.spot} />
                            </div>
                            :
                            <div className="form-row">
                                <h3>Online reservation requests coming soon.</h3>
                            </div>
                        }
                    </div>
                </Modal.Content>
                :
                ""
            }
        </Modal>
    );
};

export default InfoModal;