const Legend = () => {
    return (
        <div className="map" style={{ padding: '1vw' }}>
            <div className="modal-header">
                <h2>Legend</h2>
            </div>
            <div className='row'>
                <div className='column'></div>
                <div className='double-column spot'>
                    <div className='legend-div' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'lightgrey' }}>
                        <span>Non-Food Vendor Spot</span>
                    </div>
                </div>
                <div className='column'></div>
                <div className='double-column spot'>
                    <div className='legend-div' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'lightgreen' }}>
                        <span>Food Vendor Spot</span>
                    </div>
                </div>
                <div className='column'></div>
                <div className='double-column spot'>
                    <div className='legend-div' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'yellow' }}>
                        <span>Lions Club Booth</span>
                    </div>
                </div>
                <div className='column'></div>
                <div className='double-column spot'>
                    <div className='legend-div' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'lightblue' }}>
                        <span>Reserved</span>
                    </div>
                </div>
                <div className='column'></div>
            </div>
        </div>
    );
};

export default Legend;