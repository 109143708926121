export const reservationsData = {
    results: [
        {
            id: 1,
            name: "Billy's Banana Stand",
            email: "billy@example.com",
            reserved: 1,
            phoneNumber: "478-589-1234",
            itemsToSell: "Bananas",
            generator: "no",
            workSite: "tents-tables",
            spot: 'A15'
        },
        {
            id: 2,
            name: "Todd's Taco Stand",
            email: "todd@example.com",
            reserved: 1,
            phoneNumber: "478-589-1234",
            itemsToSell: "Tacos",
            generator: "yes",
            workSite: "tents-tables",
            spot: 'A13'
        }
    ]
};

export default reservationsData;