import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="931147214433-pg97r0i3jcjpkc7njas4bcga8q19lsev.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
);

reportWebVitals();
