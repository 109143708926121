import FoodVendorFile from '../src/assets/FOOD_ONLY_P2B_Vendor_Application_2024.pdf'
import NonFoodVendorFile from '../src/assets/Non_Food_Only_P2B_Vendor_Application_2024.pdf'

export const FoodVendorInfo = {
    earlyFee: '$80',
    lateFee: '$120',
    size: "30 ft by 30 ft",
    vendorApplicationFile: FoodVendorFile
};

export const NonFoodVendorInfo = {
    earlyFee: '$40',
    lateFee: '$60',
    size: "15 ft by 15 ft",
    vendorApplicationFile: NonFoodVendorFile
};