import LionsClub from "./LionsClub";
import InfoModal from "./InfoModal";

const EntranceRow = () => {
    return (
        <div className="row">
            <div className="column"></div>
            <div className="column"></div>
            <InfoModal triggerElement={<LionsClub spot={{ label: "" }} wide={true} />} />
            <div className="column"></div>
            <InfoModal triggerElement={<LionsClub spot={{ label: "" }} wide={true} />} />
            <div className="column"></div>
            <InfoModal triggerElement={<LionsClub spot={{ label: "" }} wide={true} />} />
            <div className="column"></div>
            <InfoModal triggerElement={<LionsClub spot={{ label: "" }} />} />
        </div>
    );
};

export default EntranceRow;