import './App.css';
import Map from './components/Map'
import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { admins } from './adminData'
import reservationsData from './reservationsData';
import Legend from './components/Legend';
import { ReservationContext } from './contexts/ReservationContext';
import img from '../src/assets/P2B Lions banner.png';
import ListViewModal from './components/ListViewModal';

function App() {
  const [user, setUser] = useState();
  const [profile, setProfile] = useState(JSON.parse(localStorage?.getItem('profile')));
  const [reservations, setReservations] = useState(reservationsData.results);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            res.data.isAdmin = admins.includes(res.data.email)
            if (res.data.isAdmin) {
              setProfile(res.data);
              localStorage.setItem('profile', JSON.stringify(res.data));
            }
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  useEffect(() => {
    const getReservations = async () => {
      const resp = await fetch('api/reservations');
      const reservationsResp = await resp.json();
      setReservations(reservationsResp.results);
    };

    getReservations()
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const logOut = () => {
    googleLogout();
    setProfile(null);
    localStorage.clear();
  };

  return (
    <div className="ui center aligned container">
      <img src={img} alt="Restroom" style={{ maxWidth: '80%' }} />
      <Legend />
      <ReservationContext.Provider value={reservations}>
        {profile?.isAdmin ? (
          <ListViewModal></ListViewModal>
        ) : (
          <></>
        )}
        <Map />
      </ReservationContext.Provider>
      <footer>
        {profile ? (
          <div>
            <p>{profile.email}</p>
            <button onClick={logOut}>Log out</button>
          </div>
        ) : (
          <button onClick={() => login()}>Sign in with Google 🚀 </button>
        )}
      </footer>
    </div>
  );
}

export default App;
