import {
    Modal,
    ModalContent,
    Button,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell
} from "semantic-ui-react"
import React from 'react';
import { ReservationContext } from '../contexts/ReservationContext';
import { CSVLink } from 'react-csv';

const ListViewModal = () => {
    const [open, setOpen] = React.useState(false);
    const reservations = React.useContext(ReservationContext);
    const actualReservations = []
    let tableData = [];
    for (let i = 0; i < reservations.length; i++) {
        let reservation = reservations[i];
        if (reservation.reserved === 1) {
            actualReservations.push(reservation);
            tableData.push(
                <TableRow>
                    <TableCell>
                        {reservation.spot}
                    </TableCell>
                    <TableCell>
                        {reservation.name}
                    </TableCell>
                    <TableCell>
                        {reservation.email}
                    </TableCell>
                    <TableCell>
                        {reservation.phoneNumber}
                    </TableCell>
                    <TableCell>
                        {reservation.itemsToSell}
                    </TableCell>
                    <TableCell>
                        {reservation.generator}
                    </TableCell>
                    <TableCell>
                        {reservation.workSite}
                    </TableCell>
                </TableRow>
            );
        }
    }
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button className="ui blue button">Show Reservations List</Button>}
        >
            <Modal.Header><h2>Reservation List</h2><CSVLink data={actualReservations} filename={"p2b-reservations.csv"}>Download</CSVLink></Modal.Header>
            <ModalContent>
                <Table celled>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Spot</TableHeaderCell>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Email</TableHeaderCell>
                            <TableHeaderCell>Phone Number</TableHeaderCell>
                            <TableHeaderCell>Items</TableHeaderCell>
                            <TableHeaderCell>Generator</TableHeaderCell>
                            <TableHeaderCell>Work Site</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {tableData}
                    </TableBody>
                </Table>
            </ModalContent>
        </Modal>
    );
};

export default ListViewModal;