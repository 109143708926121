const LionsClub = ({ spot, wide, ...rest }) => {
    let columnClass = 'column spot';
    let stallClass = 'lions-club selectable';
    if (wide) {
        columnClass = 'double-column spot';
        stallClass = 'wide-lions-club selectable';
    }
    return (
        <div className={columnClass} {...rest}>
            <div className={stallClass} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>{spot?.label}</span>
            </div>
        </div>
    )
};

export default LionsClub;